import { Place } from '../types'

const Places: Array<Place> = [
    {
        id: 1,
        type: 'DINNER',
        name: 'La Casita Blanca',
        description: '',
        town: 'Old San Juan',
        link: 'https://www.facebook.com/lacasitablancapr/',
        ig: 'https://www.instagram.com/casitablancapr/?hl=en',
        map: 'https://www.google.com/maps/place/La+Casita+Blanca/@18.4433854,-66.0562648,17z/data=!3m1!4b1!4m6!3m5!1s0x8c036f5372d88091:0x1411b3c260629a6c!8m2!3d18.4433854!4d-66.0536899!16s%2Fg%2F1tghqmcl?entry=ttu',
        tags: [
            'puerto rican',
            'authentic'
        ]
    },
    {
        id: 2,
        type: 'DINNER',
        name: 'Casita Miramar',
        description: '',
        town: 'San Juan',
        link: 'https://www.casita-miramar.com/',
        ig: 'https://www.instagram.com/casitamiramar/',
        map: 'https://www.google.com/maps/place/Casita+Miramar/@18.4558229,-66.0876826,17z/data=!3m1!4b1!4m6!3m5!1s0x8c036f2f777d09ff:0xe7ee6488788e0f78!8m2!3d18.4558229!4d-66.0851077!16s%2Fg%2F11b7l3_6gh?entry=ttu',
        tags: [
            'puerto rican'
        ]
    },
    {
        id: 3,
        type: 'DINNER',
        name: 'Marmalade',
        description: '',
        town: 'San Juan',
        link: 'https://www.marmaladepr.com/',
        ig: 'https://www.instagram.com/marmaladerestpr/',
        map: 'https://www.google.com/maps/place/Marmalade/@18.4656091,-66.1181875,17z/data=!3m1!4b1!4m6!3m5!1s0x8c036ec6b58d7c61:0x4f222a35b6cdb36!8m2!3d18.4656092!4d-66.1133166!16s%2Fg%2F11bvcv0wt1?entry=ttu',
        tags: [
            'wine',
            'course meal',
            'fusion'
        ]
    },
    {
        id: 4,
        type: 'DINNER',
        name: 'Yoko',
        description: '',
        town: 'San Juan',
        link: 'https://yoko.restaurant/',
        ig: 'https://www.instagram.com/yokorestaurant',
        map: 'https://www.google.com/maps/place/YOKO/@18.4514782,-66.0625646,17z/data=!3m1!4b1!4m6!3m5!1s0x8c036fc810b8183b:0xe5038ee21c027d18!8m2!3d18.4514782!4d-66.0599897!16s%2Fg%2F11fnw2x185?entry=ttu',
        tags: [
            'asian',
            'sushi'
        ]
    },
    {
        id: 5,
        type: 'DINNER',
        name: 'Senpai Ramen',
        description: '',
        town: 'Aguadilla',
        link: 'https://senpaipr.com/',
        ig: 'https://www.instagram.com/senpaipr/',
        map: 'https://www.google.com/maps/place/Senpai+Ramen/@18.5043677,-67.116672,17z/data=!3m1!4b1!4m6!3m5!1s0x8c0297b8e87b3da3:0x63a91c5bd497acf!8m2!3d18.5043677!4d-67.1140971!16s%2Fg%2F11h_fgq1tn?entry=ttu',
        tags: [
            'asian',
            'ramen'
        ]
    },
    {
        id: 6,
        type: 'DINNER',
        name: 'La Pícara',
        description: '',
        town: 'San Juan',
        link: 'https://www.facebook.com/LaPicarapr/',
        ig: 'https://www.instagram.com/lapicarapr/?hl=en',
        map: 'https://www.google.com/maps/place/La+P%C3%ADcara+by+Chef+Xiomy/@18.4499211,-66.0730146,15z/data=!4m2!3m1!1s0x0:0xecaad41445cec44f?sa=X&ved=2ahUKEwis3en3ibyAAxVBk2oFHW2ADv0Q_BJ6BAhXEAA&ved=2ahUKEwis3en3ibyAAxVBk2oFHW2ADv0Q_BJ6BAhrEAg',
        tags: [
            'fusion'
        ]
    },
    {
        id: 7,
        type: 'DINNER',
        name: 'Teta\'s',
        description: '',
        town: 'Cayey',
        link: 'https://linktr.ee/tetas',
        ig: 'https://www.instagram.com/tetas_bykarlaz/',
        map: 'https://www.google.com/maps/place/Teta\'s/@18.1582218,-66.1294038,17z/data=!3m1!4b1!4m6!3m5!1s0x8c034eefdf18a6bd:0xe44c6792050a7393!8m2!3d18.1582218!4d-66.1268289!16s%2Fg%2F11b6z0gbd4?entry=ttu',
        tags: [
            'puerto rican'
        ]
    },
    {
        id: 8,
        type: 'DINNER',
        name: 'Bacoa',
        description: '',
        town: 'Juncos',
        link: 'https://bacoapr.com/en/',
        ig: 'https://www.instagram.com/bacoapr/',
        map: 'https://www.google.com/maps/place/Bacoa+Finca+%2B+Fog%C3%B3n/@18.2422727,-65.8933732,17z/data=!3m1!4b1!4m6!3m5!1s0x8c0359a7dbec5219:0x4c1c6d0c088c8dc1!8m2!3d18.2422727!4d-65.8907983!16s%2Fg%2F11h4tpt7ps?entry=ttu',
        tags: [
            'farm',
            'puerto rican'
        ]
    },
    {
        id: 9,
        type: 'DINNER',
        name: 'Bohemia: Cocína en Movimiento',
        description: '',
        town: 'Cayey',
        link: 'https://bohemiacocina.com/',
        ig: 'https://www.instagram.com/bohemiacocinaenmovimiento/',
        map: 'https://www.google.com/maps/place/Bohemia+Cocina+en+Movimiento/@18.0705118,-66.1925617,12z/data=!4m19!1m12!4m11!1m3!2m2!1d-66.1925617!2d18.0776922!1m6!1m2!1s0x8c034b12e5a2c13b:0x5a818c45d3477224!2sBohemia+Cocina+en+Movimiento+PR+715+km+5.1+00736+Bo+Cayey+00736+Puerto+Rico!2m2!1d-66.1925617!2d18.0705118!3m5!1s0x8c034b12e5a2c13b:0x5a818c45d3477224!8m2!3d18.0705118!4d-66.1925617!16s%2Fg%2F11p3hh2xfp?entry=ttu',
        tags: [
            'experience',
            'course meal',
            'fusion'
        ]
    },
    {
        id: 10,
        type: 'DINNER',
        name: 'Vianda',
        description: '',
        town: 'San Juan',
        link: 'http://www.viandapr.com/',
        ig: 'https://www.instagram.com/vianda_pr/',
        map: 'https://www.google.com/maps/place/Vianda/@18.4479093,-66.0704952,15z/data=!4m2!3m1!1s0x0:0x831330282455754a?sa=X&ved=2ahUKEwia6Z3CjLyAAxUxlWoFHVguC-kQ_BJ6BAhNEAA&ved=2ahUKEwia6Z3CjLyAAxUxlWoFHVguC-kQ_BJ6BAhsEAg',
        tags: [
            'wine',
            'cocktails',
            'puerto rican'
        ]
    },
    {
        id: 11,
        type: 'DINNER',
        name: 'Wilo Eatery and Bar',
        description: '',
        town: 'San Juan',
        link: 'https://www.wilobenet.com/menu',
        ig: 'https://www.instagram.com/wiloeateryandbar/',
        map: 'https://www.google.com/maps/place/Wilo+Eatery+and+Bar/@18.4069407,-66.1063715,15z/data=!4m14!1m7!3m6!1s0x8c0369f45aa2541f:0x5cc3f410112e6fb1!2sWilo+Eatery+and+Bar!8m2!3d18.4069407!4d-66.1063715!16s%2Fg%2F11fjv4g5j_!3m5!1s0x8c0369f45aa2541f:0x5cc3f410112e6fb1!8m2!3d18.4069407!4d-66.1063715!16s%2Fg%2F11fjv4g5j_?entry=ttu',
        tags: [
            'bar',
            'fusion',
            'asian'
        ]
    },
    {
        id: 12,
        type: 'DINNER',
        name: 'Mrs. Potts',
        description: '',
        town: 'San Juan',
        link: 'https://www.opentable.com/r/mrs-potts-carolina?ref=android-share&refid=123',
        ig: 'https://www.instagram.com/mrs_potts_pr',
        map: 'https://www.google.com/maps/place/Mrs.+Potts+Bistro/@18.4431061,-66.0284415,15z/data=!4m2!3m1!1s0x0:0x6da6da7c1f720a7?sa=X&ved=2ahUKEwjqzajqrMOAAxWFRTABHTYnAdcQ_BJ6BAhPEAA&ved=2ahUKEwjqzajqrMOAAxWFRTABHTYnAdcQ_BJ6BAhkEAg',
        tags: [
            'brunch',
        ]
    },
    {
        id: 13,
        type: 'DINNER',
        name: 'La Carreta',
        description: '',
        town: 'Old San Juan | San Juan',
        link: 'https://www.mercadolacarreta.com/',
        ig: 'https://www.instagram.com/mercadolacarreta/',
        map: 'https://www.google.com/maps/place/Mercado+La+Carreta/@18.466727,-66.1192585,17z/data=!3m1!4b1!4m6!3m5!1s0x8c036f44487ad747:0xef601548147c6e23!8m2!3d18.4667271!4d-66.1143876!16s%2Fg%2F11gk9m2c3g?entry=ttu',
        tags: [
            'puerto rican',
            'brunch',
        ]
    },
    {
        id: 14,
        type: 'DINNER',
        name: 'Vittata',
        description: '',
        town: 'Aguadilla',
        link: 'https://www.facebook.com/vittatapr/',
        ig: 'https://www.instagram.com/vittatagastropub/',
        map: 'https://www.google.com/maps/place/Vittata+Gastropub/@18.4316357,-67.1545,15z/data=!4m2!3m1!1s0x0:0x5cccf74095412ac6?sa=X&ved=2ahUKEwiE0vewxMOAAxXwSDABHVxpCRMQ_BJ6BAhHEAA&ved=2ahUKEwiE0vewxMOAAxXwSDABHVxpCRMQ_BJ6BAhjEAg',
        tags: [
            'puerto rican',
            'brunch',
        ]
    },
    {
        id: 15,
        type: 'DINNER',
        name: 'La Santurcina',
        description: '',
        town: 'San Juan',
        link: 'https://www.facebook.com/p/La-Santurcina-100083725296907/',
        ig: 'https://www.instagram.com/la_santurcina/',
        map: 'https://www.google.com/maps/place/La+Santurcina/@18.4500619,-66.0738287,15z/data=!4m2!3m1!1s0x0:0x7c48f9018c8368c3?sa=X&ved=2ahUKEwiz0u_LxMOAAxU0STABHR-OCmYQ_BJ6BAhYEAA&ved=2ahUKEwiz0u_LxMOAAxU0STABHR-OCmYQ_BJ6BAhnEAg',
        tags: [
            'fusion',
            'seafood',
        ]
    },
    {
        id: 16,
        type: 'DINNER',
        name: 'Horno de Panes (Bakery)',
        description: '',
        town: 'San Juan',
        link: 'https://elhornodepane.com/',
        ig: 'https://www.instagram.com/elhornodepane',
        map: 'https://www.google.com/maps/place/El+Horno+de+Pane/@18.4262789,-66.0706306,15z/data=!4m2!3m1!1s0x0:0x4ad1100a5daf1397?sa=X&ved=2ahUKEwjO49eAxcOAAxUhRDABHUQGCIkQ_BJ6BAhSEAA&ved=2ahUKEwjO49eAxcOAAxUhRDABHUQGCIkQ_BJ6BAhxEAg',
        tags: [
            'bread',
            'bakery',
        ]
    },
    {
        id: 17,
        type: 'DINNER',
        name: 'Panoteca San Miguel(Bakery)',
        description: '',
        town: 'San Juan',
        link: 'https://puertoricoproduce.com/producers/panoteca-san-miguel',
        ig: 'https://www.instagram.com/panotecasanmiguel/?hl=en',
        map: 'https://www.google.com/maps/place/Panoteca+San+Miguel/@18.3836681,-66.0723745,15z/data=!4m2!3m1!1s0x0:0x571a5d09c1b52a66?sa=X&ved=2ahUKEwjX4syxxcOAAxXTTjABHcl3A34Q_BJ6BAhQEAA&ved=2ahUKEwjX4syxxcOAAxXTTjABHcl3A34Q_BJ6BAhfEAg',
        tags: [
            'pizza',
            'bakery',
        ]
    },
    {
        id: 18,
        type: 'DINNER',
        name: 'Mario Pagan',
        description: '',
        town: 'San Juan',
        link: 'https://www.mariopaganrest.com/',
        ig: 'https://www.instagram.com/mariopaganrestaurant/',
        map: 'https://www.google.com/maps/place/Mario+Pag%C3%A1n+Restaurant/@18.4559882,-66.0741977,15z/data=!4m6!3m5!1s0x8c036f39c72be45b:0x3581d06bad1c39cd!8m2!3d18.4559882!4d-66.0741977!16s%2Fg%2F11cn9h29s2?entry=ttu',
        tags: [
            'puerto rican',
            'dinning',
        ]
    },
    {
        id: 19,
        type: 'DINNER',
        name: 'SAGE by Mario Pagan',
        description: '',
        town: 'San Juan',
        link: 'https://www.facebook.com/SageBisteccabyMarioPagan/',
        ig: 'https://www.instagram.com/sagebisteccabymariopagan',
        map: 'https://www.google.com/maps/place/Sage+La+Bistecca+by+Mario+Pag%C3%A1n/@18.4577292,-66.0761611,15z/data=!4m2!3m1!1s0x0:0xfcba5bcc63030a84?sa=X&ved=2ahUKEwj1wqru_cqAAxV5ezABHU96CI8Q_BJ6BAhKEAA&ved=2ahUKEwj1wqru_cqAAxV5ezABHU96CI8Q_BJ6BAheEAg',
        tags: [
            'puerto rican',
            'steak',
        ]
    },
    {
        id: 20,
        type: 'DINNER',
        name: 'Umai',
        description: '',
        town: 'Guaynabo',
        link: 'https://www.umaipr.com/menu',
        ig: 'https://www.instagram.com/umaipr',
        map: 'https://www.google.com/maps/place/Umai/@18.3987543,-66.1225202,15z/data=!4m2!3m1!1s0x0:0x86fe7f216d5daddf?sa=X&ved=2ahUKEwjp1qy1_sqAAxUbk4QIHRZLAzEQ_BJ6BAhJEAA&ved=2ahUKEwjp1qy1_sqAAxUbk4QIHRZLAzEQ_BJ6BAhjEAg',
        tags: [
            'asian',
            'fusion',
        ]
    },
    {
        id: 21,
        type: 'DINNER',
        name: 'La Manada Ribs V8',
        description: '',
        town: 'Guayanilla',
        link: 'https://www.facebook.com/lamanadaribsv8/',
        ig: 'https://www.instagram.com/lamanadaribsv8/',
        map: 'https://www.google.com/maps/place/La+Manada+Ribs+V8/@18.061824,-66.7689586,15z/data=!4m6!3m5!1s0x8c1d2d2221003813:0x51fcc5df34c6214d!8m2!3d18.061824!4d-66.7689586!16s%2Fg%2F11bx8j4shz?entry=ttu',
        tags: [
            'bbq',
            'puerto rican',
            'experience',
        ]
    },
    {
        id: 22,
        type: 'BRUNCH',
        name: 'Tía',
        description: '',
        town: 'Guaynabo',
        link: 'https://www.tiacocina.com/',
        ig: 'https://www.instagram.com/tiacocina',
        map: 'https://www.google.com/maps/place/T%C3%ADa/@18.3698254,-66.0880155,15z/data=!4m2!3m1!1s0x0:0x35eca9230041d92b?sa=X&ved=2ahUKEwjH6JmjgMuAAxWnQjABHeRSDXYQ_BJ6BAhOEAA&ved=2ahUKEwjH6JmjgMuAAxWnQjABHeRSDXYQ_BJ6BAhiEAg',
        tags: [
            'brunch',
        ]
    },
]

export default Places