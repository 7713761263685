import React, { useState } from 'react'
import { Reviewer, Place } from '../types'
import places from '../data/places'
import PlacesList from './PlacesList'
import { classNames } from '../utils'

export default function ReviewerPanel({reviewer}: { reviewer: Reviewer }) {
    const [open, setOpen] = useState<boolean>(false)

    const reviewerPlaces: Array<Place | undefined> = reviewer.entries.map((id: number) => {
        return places.find(p => p.id === id)
    })

    return (
        <div className={classNames(
            'w-full my-10 px-10 py-4 font-oswald rounded',
            open ? 'bg-white border-2 border-theme' : 'bg-theme'
        )}>

            <div className={classNames(
                'w-full flex flex-col items-center justify-center text-sm cursor-pointer',
                open ? 'text-theme ' : 'text-taupe'
            )}
                 onClick={() => setOpen(!open)}>

                <div className="text-2xl font-light font-pacifico">{reviewer.name}</div>
                <div className="mt-4 font-light font-oswald text-center">{reviewer.description}</div>
            </div>

            {open &&
                <div className="py-8 w-full">
                    <PlacesList places={reviewerPlaces}/>
                </div>
            }

        </div>
    )
}