import React from 'react'
import { Reviewer } from './types'
import reviewers from './data/reviewers'
import ReviewerPanel from './components/ReviewerPanel'
import Header from './components/Header'

function App() {
    return (
        <div className="w-screen h-screen flex flex-col justify-center items-center bg-taupe">

            <Header/>

            <div className="w-full lg:w-1/3 h-full px-10 grid grid-cols-1 overflow-y-scroll">
                <div>
                    {reviewers.map((r: Reviewer) => <ReviewerPanel reviewer={r}/>)}
                </div>
            </div>
           
        </div>
    )
}

export default App
