import { Reviewer } from '../types'

const Reviews: Array<Reviewer> = [
    {
        name: 'Gerry',
        description: 'As a Puerto Rican lawyer with frequent travel, I\'ve cultivated a rich knowledge of local cuisine and confidently recommend restaurants based on my gastronomic explorations.',
        entries: [3, 4, 5, 6, 7, 8, 9, 10, 11, 1]
    },
    {
        name: 'Yury',
        description: 'Local baker. I believe a place is good when you go there consistently, try different plates, and always have a great experience.',
        entries: [12, 13, 14, 15, 16, 17, 9]
    },
    {
        name: 'Adan',
        description: 'Podcaster at El Gazebo. I leverage my podcast and social media connections to bring you great suggestions and places that leave you wanting more.',
        entries: [3, 18, 6,19, 4, 9, 20, 21, 22, 11]
    },
]

export default Reviews