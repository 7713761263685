import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Place } from '../types'
import { faMapPin, faHouse } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'


interface Properties {
    places: Array<Place | undefined>
}

export default function PlacesList({places}: Properties) {
    return (
        <ol className="list-decimal space-y-10">
            {places.map((p: Place | undefined) => {
                if (p) return (
                    <li className="px-4 py-2 bg-gray-100 border border-gray-300 rounded">

                        <div className="flex">
                            <div className="flex-1">
                                <div className="text-theme text-xl uppercase">{p.name}</div>
                                <div className="py-1 text-xs text-slate-900 uppercase font-light">{p.town}</div>

                                <div className="mt-3 space-x-1">
                                    {p.tags.map((tag: string) => (
                                        <span className="inline-flex items-center rounded-md bg-slate-900 px-2 py-1 text-xs font-light text-gray-100 ring-1 ring-inset ring-gray-500/10">{tag}</span>
                                    ))}
                                </div>
                            </div>

                            <div className="ml-4 flex flex-col justify-between items-end">
                                <a href={p.link} target={'_blank'} rel="noreferrer">
                                    <FontAwesomeIcon icon={faHouse} className="text-theme"/>
                                </a>
                                <a href={p.ig} target={'_blank'} rel="noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} size="lg" className="text-theme"/>
                                </a>
                                <a href={p.map} target={'_blank'} rel="noreferrer" className="mr-0.5">
                                    <FontAwesomeIcon icon={faMapPin} size="lg" className="text-theme"/>
                                </a>
                            </div>
                        </div>

                    </li>
                )
                else return ''
            })}
        </ol>
    )
}